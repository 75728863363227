*{
  --accent-color: #8C84FF;
  font-family: Manrope;
}
#container {
	text-align: center;
}

#text {
	display: inline-block;
	vertical-align: middle;
	color: orange;
	letter-spacing: 2px;
}

#cursor {
	display: inline-block;
	vertical-align: middle;
	width: 3px;
	height: 50px;
	background-color: orange;
	animation: blink .75s step-end infinite;
}

@keyframes blink {
	from, to { 
		background-color: transparent 
	}
	50% { 
		background-color: orange; 
	}
}

.App {
  background-color: #0C0C31;
}

.navbar {
  display: flex;
  height: 10vh;
  align-items: center;
  gap: 2%;
}

.logo {
  margin-right: auto;
  margin-left: 2em;
}

.nav-item {
  text-decoration: none;
  color: black;
  font-size: 1.5em;
  color: var(--accent-color);
  font-weight: 500;
}

.nav-item::after {
  height: 10px;
  width: 20px;
  color: red;
  content: "";
}

.nav-item:hover {
  text-decoration: none;
  color: var(--accent-color);
}

.nav-cta-btn {
  margin-right: 5%;
  font-size: 1.3em;
  background-color: transparent;
  border-radius: 30px;
  border: 1.5px solid var(--accent-color);
  color: var(--accent-color);
  text-decoration: none;
  padding: 5px 10px;

}

.nav-cta-btn:hover {
transform: scale(1.1);
transition: 300ms;
cursor: pointer;
color: var(--accent-color);
text-decoration: none;

}

.menu-icon{
  font-size: 4em;
  background-color: transparent;
  border: none;
  color: white;
  padding: 0;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#nav-model {
  background-color: rgba(22, 19, 19, 0.927);
  position: fixed;
  height: 100%;
  width: 100%;
  margin: 0;
  top: 0;
  left: 0;
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.85em; 
}

#logo-img{
  height: 50px;
}

#nav-model-container{
  display: flex;
  flex-direction: column;
  font-size: 4em;
  text-align: center;
  margin-top: 1em;
}


@media (max-width: 500px) {



  .my-tech h2{
      font-size: 1em;
  }
  
}

.hero {
display: flex;
flex-direction: column;
text-align: left;
margin-top: 12%;
animation: slide-in 1s;
height: 90vh;
margin-left: 20%;
margin-right: 20%;

}

@keyframes slide-in {
  0% {
    margin-left: -100rem;
  }
  100% {
    margin-top: 12%; 
  }
  
}


.hero-header {
font-size: 2em;
margin: 0;
color: rgba(140, 132, 255, 0.6);
text-align: left;
}

.hero-name {
font-size: 4.5em;
margin: 0;
color: #EDEDF8;
text-align: left;
}

.hero-phrase {
  font-size: 4.5em;
  color: rgba(255, 255, 255, 0.402);
  text-align: left;
}

.hero-bio {
  width: 50%;
  color: rgba(255, 255, 255, 0.402);
  text-align: left;
}

.hero-bio span {
  color: var(--accent-color);
}


@media (max-width: 900px) {

  .hero {
    width: 80%;
    margin-left: 10%;
  }


  .hero-name {
    font-size: 3.5em;
  }

  .hero-phrase {
    font-size: 3.5em;
  }

.hero-bio {
  width: 80%;
}
  
}








.my-tech {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
}

.my-tech h2 {
  font-size: 4.5em;
  font-weight: 600;
  color: #EDEDF8;
}

.tech-container {
  color:rgba(140, 132, 255);
  font-size: 8em;
  margin-bottom: 2em;
  padding-bottom: -1em;
}






@media (max-width: 800px) {

  .my-tech h2 {
    font-size: 3.5em;
  }

  
}

@media (max-width: 500px) {
  .my-tech h2 {
    font-size: 2.4em;
  }
}

.card {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.projects {
  min-height: 120vh;
  display: flex;
  position: relative;
}

.project-spacer {
  margin-left: 15%;
}



.project-slider{
height: 250vh;
}
.project-text-container{
max-width: 30%;
}



.project-item-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10em;
  animation: slide-right 1s;
  flex-wrap: wrap;
}

/* @keyframes slide-right {
  0% {
    margin-left: -100em;
  }
  100% {
    margin-left: 0%;
  }
   */
/* } */
.project-text-container{
  color: white;
}


.middle-project-container {
  width: 90%;
  margin-left: -5em;
}

.laptop-card {
  width: 60%;
  z-index: 10;
  height: 400px;
  background-image: url("../public/laptop4.png");
  background-size: contain;
  background-repeat: no-repeat;
  /* background-color: red; */
  position: relative;
}

.laptop-card2 {
  width: 60%;
  z-index: 10;
  height: 400px;
  background-image: url("../public/laptop2.png");
  background-size: contain;
  background-repeat: no-repeat;
  /* background-color: red; */
  position: relative;
}

.laptop-card3 {
  width: 60%;
  z-index: 10;
  height: 500px;
  background-image: url("../public/laptop3.png");
  background-size: contain;
  background-repeat: no-repeat;
  /* background-color: red; */
  margin-top: 40px;
  position: relative;
}


.card1-bg{
  width: 73%;
  margin-top: 5.5%;
  margin-left: 13.5%;
 }
.card2-bg{
 width: 73%;
 margin-top: 5.5%;
 margin-left: 13.5%;
}

@media (max-width: 1100) {
  .card1-bg{
    width: 73%;
    margin-top: 5.5%;
    margin-left: 13.5%;
   }
}

@media (max-width: 900) {
  .project-item-container{
    flex-direction: column;
  }

 
}

@media (max-width: 500px) {
  .project-item-container {
    flex-direction: column;
    margin: 0;
    width: 100%;
  }

  .project-text-container{
    min-width: 300px;
    margin-bottom: 2em;
    text-align: center;
  }

  .middle-project-container{
    flex-direction: column;
    margin: 0;
    width: 100%;
  }

  .project-spacer {
    margin: 0;
  }

  .laptop-card{
    width: 90%;
  }

  .laptop-card2{
    width: 90%;
  }
}

.card-btn {
  position: absolute;
  z-index: 3;
  font-size: 1.3em;
  background-color: transparent;
  border-radius: 30px;
  border: 2px solid var(--accent-color);
  color: var(--accent-color);
  top: 20%;
  font-weight: 600;
}
.card-btn:hover{
  transform: scale(1.1);
  transition: 300ms;
}

.card-btn-2{
right: 10%;
}

.contact {
  height: 100vh;
  color: #EDEDF8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 2em;
}

.contact h1 {
  font-size: 6em;
  color: var(--accent-color);
}

.span {
  display: flex;
  align-items: center;
  width: 50%;
  font-size: 2em;
  justify-content: center;
}


@media (max-width: 500px) {
  .contact h1 {
    font-size: 4em;
  }

  .contact span{
    font-size: 1.4em;
  }
  
}

.span ion-icon:hover {
  -webkit-animation: vibrate-2 0.3s linear infinite both;
  animation: vibrate-2 0.3s linear infinite both;
}

 @-webkit-keyframes vibrate-2 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  40% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  60% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  80% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@keyframes vibrate-2 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  40% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  60% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  80% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}





.span a {
margin-left: 3%;
text-decoration: none;
color: #EDEDF8;
}
.span a{
margin-left: 3%;
text-decoration: none;
}


.social-contianer{
font-size: 4em;
display: flex;
gap: 8%;
color: #8C84FF;
}

.social-contianer a {
  text-decoration: none;
  color: var(--accent-color);
}

.social-contianer a:hover {
transform: scale(1.1);
}

 @-webkit-keyframes rotate-diagonal-2 {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: rotate3d(-1, 1, 0, 180deg);
            transform: rotate3d(-1, 1, 0, 180deg);
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, 360deg);
            transform: rotate3d(-1, 1, 0, 360deg);
  }
}
@keyframes rotate-diagonal-2 {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: rotate3d(-1, 1, 0, 180deg);
            transform: rotate3d(-1, 1, 0, 180deg);
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, 360deg);
            transform: rotate3d(-1, 1, 0, 360deg);
  }
}


.project-btn{
  border: 1px solid #8C84FF;
  background-color: transparent;
  color: #8C84FF;
  margin-right: 15px;
  border-radius: 26px;
  font-size: 1.2em;
  padding: 4px 16px;
  cursor: pointer
}

.project-btn:hover {
  transform: scale(1.1);
  transition: 300ms;
}

.project-btn a {
  text-decoration: none;
  color: #8C84FF;
}

